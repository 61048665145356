<template>
  <div>
    <div class="question" v-if="this.testQuestion">
      <SingleTest
        :question="this.testQuestion"
        v-if="this.testQuestion.type === 1 || this.testQuestion.type === '1'"
      ></SingleTest>
      <MultipleTest
        :question="this.testQuestion"
        v-if="this.testQuestion.type === 2 || this.testQuestion.type === '2'"
      ></MultipleTest>
      <SubjectiveTest
        :question="this.testQuestion"
        v-if="this.testQuestion.type === 3 || this.testQuestion.type === '3'"
      ></SubjectiveTest>
    </div>
  </div>
</template>

<script>

import SingleTest from '@/components/course/SingleTest'
import MultipleTest from '@/components/course/MultipleTest'
import SubjectiveTest from '@/components/course/SubjectiveTest'

export default {
  components: { SingleTest: SingleTest, MultipleTest: MultipleTest, SubjectiveTest: SubjectiveTest },
  mounted () {
    // console.log(this.testQuestion, 'question--question test')
  },
  computed: {
    testQuestion () {
      return this.$store.state.study.testQuestion.question
    }
  }

}
</script>

<style lang="scss" scoped>
.question {
  padding: 1.389vw;
  background-color: rgb(243, 233, 217);
}
</style>
