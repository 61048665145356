<template>
  <div>
    <div class="question" v-if="this.question" :key="this.question.title">
      <div class="question-type">
        单选题 ID:{{ this.question.id }} 难度：{{ this.question.ddf }}
      </div>
      <div>
        <div v-html="this.question.title" class="question-title"></div>
        <div v-if="this.question.titlepic">
          <img src="this.question.titlepic" width="80%" />
        </div>
      </div>
      <van-radio-group
        v-model="radio"
        :disabled="this.question.disabled"
        :key="this.question.title"
      >
        <van-radio
          name="1"
          class="question-item"
          v-if="this.question.a !== '-' || this.question.a === ''"
          @click="choice('1')"
          >{{ this.question.a }}
          <span
            v-if="this.question.correct === 1 && this.question.answerShow"
            class="correct"
          >
            √ 本题答案</span
          >
          <span
            v-if="
              this.question.correct !== 1 &&
              this.question.answerShow &&
              this.question.mychoice === '1'
            "
            class="wrong"
          >
            × 选择错误</span
          >
          <span
            v-if="this.question.correct === 1 && this.question.mychoice === '1'"
            class="correct"
          >
            √ 选择正确</span
          >
        </van-radio>
        <van-radio
          name="2"
          class="question-item"
          v-if="this.question.b !== '-' || this.question.b === ''"
          @click="choice('2')"
          >{{ this.question.b }}
          <span
            v-if="this.question.correct === 2 && this.question.answerShow"
            class="correct"
          >
            √ 本题答案</span
          >
          <span
            v-if="
              this.question.correct !== 2 &&
              this.question.answerShow &&
              this.question.mychoice === '2'
            "
            class="wrong"
          >
            × 选择错误</span
          >
          <span
            v-if="this.question.correct === 2 && this.question.mychoice === '2'"
            class="correct"
          >
            √ 选择正确</span
          >
        </van-radio>
        <van-radio
          name="3"
          class="question-item"
          v-if="this.question.c !== '-' || this.question.c === ''"
          @click="choice('3')"
          >{{ this.question.c }}
          <span
            v-if="this.question.correct === 3 && this.question.answerShow"
            class="correct"
          >
            √ 本题答案</span
          >
          <span
            v-if="
              this.question.correct !== 3 &&
              this.question.answerShow &&
              this.question.mychoice === '3'
            "
            class="wrong"
          >
            × 选择错误</span
          >
          <span
            v-if="this.question.correct === 3 && this.question.mychoice === '3'"
            class="correct"
          >
            √ 选择正确</span
          >
        </van-radio>
        <van-radio
          name="4"
          class="question-item"
          v-if="this.question.d !== '-' || this.question.d === ''"
          @click="choice('4')"
          >{{ this.question.d }}
          <span
            v-if="this.question.correct === 4 && this.question.answerShow"
            class="correct"
          >
            √ 本题答案</span
          >
          <span
            v-if="
              this.question.correct !== 4 &&
              this.question.answerShow &&
              this.question.mychoice === '4'
            "
            class="wrong"
          >
            × 选择错误</span
          >
          <span
            v-if="this.question.correct === 4 && this.question.mychoice === '4'"
            class="correct"
          >
            √ 选择正确</span
          >
        </van-radio>
      </van-radio-group>
      <div v-if="this.question.answerShow" class="answer">
        <div class="answer-title">试题解析</div>
        <div class="answer-content">{{ this.question.answer }}</div>
      </div>
      <br />
      <van-button
        type="info"
        size="small"
        block
        @click="submit"
        v-if="!this.question.mychoice"
        >确认(确认后将无法修改)</van-button
      >
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
const api = new Vue()
export default {
  data () {
    return {
      radio: '',
      successive: 0,
      answerShow: this.question.answerShow,
      correctIf: '',
      mychoice: '',
      disabled: this.question.disabled ? this.question.disabled : false
    }
  },
  props: ['question'],
  mounted () {
    // console.log('又一次执行')
    // console.log(this.disabled, 'disabled')
  },
  methods: {
    choice (e) {
      if (!this.answerShow) { this.mychoice = e }
    },
    submit () {
      console.log(this.question, 'question-----')
      const correctChoice = this.question.correct.toString()

      if (this.mychoice === '') {
        api.$msg('您还没有选择答案呢')
        return
      }
      this.disabled = true
      if (this.mychoice === correctChoice) {
        this.successive++
        this.correctIf = true
        this.testStatus = 1

        this.$store.dispatch('updateScore', {
          uid: this.uid,
          score: this.question.score,
          field: 'wisdom'
        })
      } else {
        if (this.successive >= 0) this.successive = -1
        else this.successive--
        this.answerShow = true
        this.correctIf = false
        this.testStatus = -1
      }
      // 更新数据
      this.localquestionArr = this.question
      this.localquestionArr.uid = this.uid
      this.localquestionArr.mychoice = this.mychoice
      this.localquestionArr.studytime = Date.parse(new Date()) / 1000
      this.localquestionArr.correctIf = this.correctIf
      this.localquestionArr.answerShow = this.answerShow
      this.localquestionArr.disabled = this.disabled

      var localPaperInfo = this.getPaperInfo
      this.$store.commit('getPaperQuestionInfoMutation', this.localquestionArr)
      localPaperInfo.push(this.localquestionArr)
      localStorage.setItem('uidPaperInfo', JSON.stringify(localPaperInfo))
      this.$store.commit('uidPaperInfo', localPaperInfo)
    }
  },
  computed: {
    uid () {
      return localStorage.getItem('uid')
    },
    pointid () {
      return localStorage.getItem('TestPointid')
    },
    courseid () {
      return localStorage.getItem('StudyCourseId')
    },
    getPaperInfo () {
      var uidPaperInfo = localStorage.getItem('uidPaperInfo')
      if (uidPaperInfo === '') uidPaperInfo = null
      var uidPaperInfoArr = []
      if (uidPaperInfo !== null) {
        uidPaperInfoArr = JSON.parse(uidPaperInfo)
      }
      return uidPaperInfoArr
    }
  },
  watch: {
    question () {
      this.radio = ''
      this.mychoice = ''
      this.answerShow = false
      this.disabled = false
      this.correctIf = false
    }
  },
  beforeUnmount () {
    this.$store.commit('getPaperQuestionInfoMutation', [])
  }
}
</script>

<style lang="scss" scoped>
.question {
  padding: 1.389vw;
  background-color: rgb(243, 233, 217);
}
.question-type {
  color: #555;
  font-size: 4.167vw;
  padding-left: 1.389vw;
}
.question-title {
  color: #111;
  font-size: 4.167vw;
  line-height: 8.333vw;
  padding: 2.778vw;
}
.question-item {
  padding-left: 2.778vw;
}
::v-deep .van-radio__label {
  line-height: 8.333vw;
  color: rgb(90, 102, 56);
  padding-top: 2.778vw;
  font-size: 4.167vw;
}
::v-deep .van-radio__icon {
  padding-top: 2.778vw;
}
.answer {
  background-color: rgb(197, 230, 245);
  padding: 1.389vw;
  font-size: 4.167vw;
}
.answer-title {
  line-height: 8.333vw;
  color: rgb(66, 107, 109);
}
.answer-content {
  color: cadetblue;
  line-height: 5.556vw;
}
.correct {
  color: green;
  font-size: 3.056vw;
}
.wrong {
  color: red;
  font-size: 3.056vw;
}
.wait {
  margin: 2.778vw auto;
  font-size: 3.611vw;
  color: coral;
  width: 55.556vw;
}
</style>
