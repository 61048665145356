<template>
  <div>
    <div :class="classname" @click="getQuestionInfo(id, type)">
      {{ itemIndex }}
    </div>
  </div>
</template>
<script>

export default {
  data () {
    return {
      uidPaperInfo: ''
      //   style: ''
    }
  },
  props: ['itemIndex', 'id', 'type'],
  methods: {
    getQuestionInfo (id, type) {
      //   console.log(this.uidPaperInfo, 'uidPaperInfo this 11')
      //   this.style = 'paper-item-select'
      this.isClk = {
        id: id,
        type: type
      }
      //   console.log(this.isClick(this.$store.state.study.isClick, this.id, this.type))
      //   console.log(this.$store.state.study.isClick, 'isclk')
      //   console.log(this.isClick(this.$store.state.study.isClick, this.id, this.type))
      const dd = this.$store.state.study.isClick
      dd.push(this.isClk)
      console.log(dd, 'dd')
      this.$store.commit('isClick', dd)
      //   console.log(this.$store.state.study.isClick, 'isclk')

      var question = {}
      if (this.getPaperInfo.length > 0) {
        // console.log(this.getPaperInfo, '+++')
        const dd = this.searchKeyValue(this.getPaperInfo, 'id', id, 'type', type)
        // console.log(dd, '+++dd')
        if (dd.length > 0) {
          question.question = dd[0]
          this.$store.commit('getPaperQuestionInfoMutation', question)
          localStorage.setItem('testQuestion', JSON.stringify(question))
          //   console.log(question, 'dd---question')
          //   console.log('+++ 本地数据')
          return
        }
      }

      console.log('+++ 重新请求')
      this.$store.dispatch('getPapreQuestionInfo', {
        objid: id,
        type: type,
        key: Math.random()
      })
    },
    searchKeyValue (lists, key1, value1, key2, value2) {
      var res = lists.filter(item => item[key1] === value1)
      res = res.filter(item => item[key2] === value2)
      return res
    },

    submitCheck (lists, id, type) {
      const res = this.searchKeyValue(lists, 'id', id, 'type', type)
      if (res.length > 0) {
        if (res[0].correctIf) {
          return 'TRUE'
        } else {
          return 'WRONG'
        }
      } else {
        return false
      }
    },
    isClickEments (list, id, type) {
      if (list.length === 0) return false
      //   return true
      const res = this.searchKeyValue(list, 'id', id, 'type', type)
      //   var res = list.filter(item => item.id === id)
      if (res.length > 0) {
        return true
      } else {
        return false
      }
    }
  },
  computed: {
    getPaperInfo () {
      let uidPaperInfo = this.$store.state.study.uidPaperInfo
      //   console.log(uidPaperInfo, ' uidPaperInfo   uidPaperInfo')
      if (uidPaperInfo.length > 0) {
        return uidPaperInfo
      } else {
        uidPaperInfo = localStorage.getItem('uidPaperInfo')
      }
      //   const uidPaperInfo = this.$store.state.study.uidPaperInfo || localStorage.getItem('uidPaperInfo')
      //   console.log(uidPaperInfo, 'ipppp')
      if (uidPaperInfo === '') { uidPaperInfo = null }
      var uidPaperInfoArr = []
      if (uidPaperInfo !== null) {
        uidPaperInfoArr = JSON.parse(uidPaperInfo)
      }
      return uidPaperInfoArr
    },
    isClick () {
      return this.$store.state.study.isClick
    },
    classname: {
      get () {
        if (this.submitCheck(this.getPaperInfo, this.id, this.type) === 'TRUE') { return 'paper-item-right' } else if (this.submitCheck(this.getPaperInfo, this.id, this.type) === 'WRONG') { return 'paper-item-wrong' } else if (this.isClickEments(this.isClick, this.id, this.type)) { return 'paper-item-select' } else return 'paper-item-unselect'
      },
      set () {
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.paper-item-unselect {
  color: blue;
  float: left;
  padding: 1.667vw;
  margin: 1.389vw;
  background-color: cornsilk;
  font-size: 4.167vw;
}
.paper-item-select {
  color: rgb(245, 114, 39);
  float: left;
  padding: 1.667vw;
  margin: 1.389vw;
  background-color: rgb(241, 240, 234);
  font-size: 4.167vw;
}
.paper-item-right {
  color: white;
  float: left;
  padding: 1.667vw;
  margin: 1.389vw;
  background-color: green;
  font-size: 4.167vw;
}
.paper-item-wrong {
  color: white;
  float: left;
  padding: 1.667vw;
  margin: 1.389vw;
  background-color: red;
  font-size: 4.167vw;
}
</style>
