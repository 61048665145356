<template>
  <div>
    <div class="question" v-if="this.questionlist">
      <div
        class="question-type"
        v-if="type === 1 && this.questionlist.totalnum > 0"
      >
        单选题 ({{ this.questionlist.totalnum }} 道 / 共{{
          this.questionlist.score
        }}分 )
      </div>
      <div
        class="question-type"
        v-else-if="type === 2 && this.questionlist.totalnum > 0"
      >
        多选题 ( {{ this.questionlist.totalnum }} 道 / 共{{
          this.questionlist.score
        }}分 )
      </div>
      <div
        class="question-type"
        v-else-if="type === 3 && this.questionlist.totalnum > 0"
      >
        主观题 ( {{ this.questionlist.totalnum }} 道 / 共{{
          this.questionlist.score
        }}分 )
      </div>
      <div class="van-clearfix pointlist">
        <div
          class="float-item"
          v-for="(question, index) in questionlist.list"
          :key="question"
        >
          <Items :itemIndex="index + 1" :id="question" :type="type"></Items>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import Items from '@/components/course/Items'
export default {
  data () {
    return {
      classname: 'paper-item-unselect'
    }
  },
  components: { Items },
  props: ['questionlist', 'type'],
  methods: {
    getQuestionInfo (id, type) {
      this.$store.dispatch('getPapreQuestionInfo', {
        objid: id,
        type: type,
        key: Math.random()
      })
    }

  }
}
</script>
<style lang="scss" scoped>
.question-type {
  color: rgb(241, 239, 237);
  line-height: 8.333vw;
  padding-left: 2.778vw;
  font-size: 3.611vw;
  background-color: cornflowerblue;
}
.paper-item-unselect {
  color: red;
  float: left;
  padding: 1.389vw;
  margin: 0.833vw;
  background-color: cornsilk;
}
</style>
