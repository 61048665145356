<template>
  <div>
    <div class="question" v-if="this.question">
      <div class="question-type">
        论述题 ID:{{ this.question.id }} 难度：{{ this.question.ddf }}
      </div>
      <div>
        <div v-html="this.question.title" class="question-title"></div>
        <div v-if="this.question.titlepic">
          <img src="this.question.titlepic" width="80%" />
        </div>
      </div>
      <van-field
        v-model="message"
        rows="3"
        autosize
        type="textarea"
        placeholder="请输入输入您的理解"
        maxlength="100"
        show-word-limit
        :disabled="disabled"
      />
      <div v-if="this.answerShow" class="answer">
        <div class="answer-title">试题解析</div>
        <div class="answer-content">{{ this.question.answer }}</div>
      </div>
      <br />
      <van-button
        type="info"
        size="small"
        block
        @click="submit"
        v-if="!this.answerShow"
        >提交</van-button
      >
      <van-button type="info" size="small" block @click="next" v-else
        >下一题</van-button
      >
    </div>
    <div v-else class="wait">系统正在搜索试题......</div>
  </div>
</template>

<script>
import Vue from 'vue'
const api = new Vue()
export default {
  data () {
    return {
      message: '',
      answerShow: false,
      disabled: false,
      correct: ''
    }
  },
  props: ['question', 'type'],
  methods: {
    submit () {
      //   const localquestion = JSON.parse(localStorage.getItem('question'))
      //   const correctChoice = localquestion.correct.toString()
      this.objid = this.question.id

      if (this.message === '') {
        api.$msg('您还什么都没有写')
        return
      }
      // 更新数据库
      this.studyInfo = {
        uid: this.uid,
        pointid: this.pointid,
        courseid: this.courseid,
        objid: this.objid,
        type: this.type,
        successive: this.message,
        studytime: Date.parse(new Date()) / 1000
      }
      this.$store.dispatch('updateStudyInfo', this.studyInfo)
      //   // 保存到local
      //   //   var localstudyinfo = JSON.parse(localStorage.getItem('studyInfo'))
      //   //   if (localstudyinfo) {
      //   //     localstudyinfo.push(this.studyInfo)
      //   //   } else {
      //   //     localstudyinfo = this.studyInfo
      //   //   }
      //   //   localStorage.setItem('studyInfo', JSON.stringify(localstudyinfo))

      //   if (this.correct) {
      //     // 下一题
      //     this.$store.commit('getQuestionInfoMutation', '')
      this.answerShow = true
      this.disabled = true
    },
    next () {
      // 下一道试题
      this.answerShow = false
      this.disabled = false
      this.radio = ''
      this.$store.commit('getQuestionInfoMutation', '')
      this.$store.dispatch('getDoneInfo', {
        uid: this.uid,
        TestPointid: this.pointid,
        getType: 'ONLY',
        courseid: 0,
        key: Math.random()
      })
    }
  },
  computed: {
    uid () {
      return localStorage.getItem('uid')
    },
    pointid () {
      return localStorage.getItem('TestPointid')
    },
    courseid () {
      return localStorage.getItem('StudyCourseId')
    }

  },
  beforeUnmount () {
    this.$store.commit('getPaperQuestionInfoMutation', [])
  }
}

</script>

<style lang="scss" scoped>
.question {
  padding: 1.389vw;
  background-color: rgb(243, 233, 217);
}
.question-type {
  color: #555;
  font-size: 4.167vw;
  padding-left: 1.389vw;
}
.question-title {
  color: #111;
  font-size: 4.167vw;
  line-height: 8.333vw;
  padding: 2.778vw;
}

.answer {
  background-color: rgb(197, 230, 245);
  padding: 1.389vw;
  font-size: 4.167vw;
}
.answer-title {
  line-height: 8.333vw;
  color: rgb(66, 107, 109);
}
.answer-content {
  color: cadetblue;
  line-height: 5.556vw;
}

.wait {
  margin: 2.778vw auto;
  font-size: 3.611vw;
  color: coral;
  width: 55.556vw;
}
</style>
