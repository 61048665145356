<template>
  <div>
    <div>
      <div v-if="this.paper.pointid > 0">
        <div class="title">试卷信息</div>
        <div class="paperinfo">
          总分{{ this.paper.totalscore }}分 / 不限时<br />
          单选题: {{ this.paper.total.singlenum }} 道 / 多选题:
          {{ this.paper.total.multiplenum }} 道 / 主观题:
          {{ this.paper.total.subjectivenum }}道
        </div>
        <br />
        <div class="paperinfo">
          本节您测试{{ this.$store.state.study.testinfo.testtimes }}次,最高分{{
            this.$store.state.study.testinfo.max
          }}，最低分{{ this.$store.state.study.testinfo.min }}，平均分{{
            this.$store.state.study.testinfo.avg
          }}
        </div>
        <div class="defen">您当前的得分：{{ defen }} 分</div>
        <Questionplane
          :questionlist="this.paper.paper.single"
          :type="1"
        ></Questionplane>
        <Questionplane
          :questionlist="this.paper.paper.multiple"
          :type="2"
        ></Questionplane>
        <Questionplane
          :questionlist="this.paper.paper.subjective"
          :type="3"
        ></Questionplane>
        <div class="editback" @click="submit">确认提交试卷</div>
        <QuestionTest></QuestionTest>
      </div>
      <van-loading size="24px" v-else vertical
        >试卷正在加载中......</van-loading
      >
    </div>
  </div>
</template>

<script>
import Questionplane from '@/components/course/Questionplane'
import QuestionTest from '@/components/course/QuestionTest'
// import Vue from 'vue'
// const msg = new Vue()
export default ({
  props: ['paper'],
  components: {
    Questionplane, QuestionTest
  },
  beforeMount () {
    this.$store.commit('hideShowTabbar', false)
  },
  mounted () {
  },
  computed: {
    getPaperInfo () {
      let uidPaperInfo = this.$store.state.study.uidPaperInfo
      if (uidPaperInfo.length > 0) {
        return uidPaperInfo
      } else {
        uidPaperInfo = localStorage.getItem('uidPaperInfo')
      }
      var uidPaperInfoArr = []
      if (uidPaperInfo !== null) {
        uidPaperInfoArr = JSON.parse(uidPaperInfo)
      }
      return uidPaperInfoArr
    },
    defen: {
      get () {
        let scr = 0
        for (var i = 0; i < this.getPaperInfo.length; i++) {
          if (this.getPaperInfo[i].correctIf) {
            scr = scr + Number(this.getPaperInfo[i].score)
            // console.log(this.getPaperInfo)
          }
        }
        return Number(scr)
      },
      set () {

      }
    },
    papers: {
      get () {
        let localpapers = this.$store.state.study.papers
        if (localpapers.length > 0) {
          return localpapers
        } else {
          localpapers = localStorage.getItem('papers')
        }
        var papersArr = []
        if (localpapers !== null) {
          papersArr = JSON.parse(localpapers)
        }
        return papersArr
      },
      set () {

      }
    }
  },
  methods: {
    submit () {
      const res = this.ckeckSubmit(this.getPaperInfo)
      console.log(res)
      if (res) {
        const classid = localStorage.getItem('classid')
        const uid = localStorage.getItem('uid')

        const scoretotal = {
          classid: classid,
          courseid: this.paper.courseid,
          uid: uid,
          pointid: this.paper.pointid,
          kstype: 1,
          paperid: 0,
          single: '-',
          mult: '-',
          subj: '-',
          pg: 2,
          totalscore: this.paper.totalscore,
          defen: res.score,
          subtime: Date.parse(new Date()) / 1000
        }
        this.$store.dispatch('scoretotal', scoretotal)
        // 修改state.study.papers
        this.paper.defen = res.score
        this.paper.submit = true
        this.paper.subtime = Date.parse(new Date()) / 1000
        this.paper.uid = uid
        const answer = {
          answer: {
            single: res.single,
            mult: res.mult,
            subj: res.subj
          }
        }
        // 增加值
        Object.assign(this.paper, answer)
        console.log(this.paper, 'ppppp')
        // 修改 根据  pointid
        // const papersLcl = this.papers
        for (var i = 0; i < this.papers.length; i++) {
          if (this.papers[i].pointid === this.paper.pointid) {
            this.papers[i] = this.paper
          }
        }
        console.log(this.papers, 'papers')
        localStorage.setItem('papers', JSON.stringify(this.papers))
        // 让其重新请求测试情况
        localStorage.setItem('TestInfo', [])
        this.$store.commit('TestinfoMutation', [])
        this.$store.commit('isClick', [])
        // 有待更新 试题信息传送失败
        // if (res.single.length > 0) {
        //   scoretotal.single = JSON.stringify(res.single)
        // }
        // if (res.mult.length > 0) {
        //   scoretotal.mult = JSON.stringify(res.mult)
        // }
        // if (res.subj.length > 0) {
        //   scoretotal.subj = JSON.stringify(res.subj)
        // }
        // console.log(scoretotal)
        this.$router.push('/Testinfo')
      } else {
        this.$msg('再检查一下，还有题目没有回答哦^_^')
      }
    },
    ckeckSubmit (lists) {
      const paperinfo = this.$store.state.study.paper
      let score = 0
      const res = {}
      const single = []
      const mult = []
      const subj = []
      if (lists.length < paperinfo.total.multiplenum + paperinfo.total.singlenum + paperinfo.total.subjectivenum) {
        return false
      }
      for (let i = 0; i < lists.length; i++) {
        if (!lists[i].studytime) {
          return false
        }
        if (lists[i].correctIf) {
          //   console.log(typeof lists[0].score)
          score = score + Number(lists[i].score)
        }
        if (lists[i].type === 1) {
          single.push(lists[i])
        } else if (lists[i].type === 2) {
          mult.push(lists[i])
        } else if (lists[i] === 3) {
          subj.push(lists[i])
        }
      }
      res.score = score
      res.single = single
      res.mult = mult
      res.subj = subj
      return res
    }
  }
})
</script>

<style lang="scss" scoped>
.title {
  color: cadetblue;
  font-size: 3.889vw;
  padding: 2.778vw;
}
.paperinfo {
  color: cadetblue;
  font-size: 3.611vw;
  padding-left: 2.778vw;
  line-height: 5.556vw;
}
.editback {
  margin-top: 5px;
  background-color: #e7d8d8;
  text-align: center;
  color: #a17347;
  padding: 2.778vw 0;
  font-size: 14.4px;
}
.defen {
  margin: 2.778vw;
  color: red;
  font-size: 3.611vw;
  font-weight: bold;
}
.btn {
  background-color: #f5ebd0;
  //   display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #f0960f;
  padding: 0.833vw 0.556vw;
  font-size: 3.611vw;
}
</style>
