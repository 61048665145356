var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(this.question)?_c('div',{staticClass:"question"},[_c('div',{staticClass:"question-type"},[_vm._v(" 多选题 ID:"+_vm._s(this.question.id)+" 难度："+_vm._s(this.question.ddf)+" ")]),_c('div',[_c('div',{staticClass:"question-title",domProps:{"innerHTML":_vm._s(this.question.title)}}),(this.question.titlepic)?_c('div',[_c('img',{attrs:{"src":"this.question.titlepic","width":"80%"}})]):_vm._e()]),_c('van-checkbox-group',{ref:"checkboxGroup",attrs:{"disabled":this.question.disabled},model:{value:(_vm.result),callback:function ($$v) {_vm.result=$$v},expression:"result"}},[(this.question.a !== '-' || this.question.a === '')?_c('van-checkbox',{staticClass:"question-item",attrs:{"name":"1","shape":"square"},on:{"click":function($event){return _vm.choice('1')}}},[_vm._v(_vm._s(this.question.a)+" "),(this.question.acorrect === 1 && this.question.answerShow)?_c('span',{staticClass:"correct"},[_vm._v(" 本题答案")]):_vm._e(),(
            this.question.acorrect === 1 && this.question.mychoiceA === '1'
          )?_c('span',{staticClass:"right"},[_vm._v(" √ 选择正确")]):(
            this.question.answerShow &&
            this.question.acorrect === 1 &&
            this.question.mychoiceA === ''
          )?_c('span',{staticClass:"wrong"},[_vm._v(" × 未选择")]):(
            this.question.answerShow &&
            this.question.acorrect !== 1 &&
            this.question.mychoiceA === '1'
          )?_c('span',{staticClass:"wrong"},[_vm._v(" × 选择错误")]):_vm._e()]):_vm._e(),(this.question.b !== '-' || this.question.b === '')?_c('van-checkbox',{staticClass:"question-item",attrs:{"name":"2","shape":"square"},on:{"click":function($event){return _vm.choice('2')}}},[_vm._v(_vm._s(this.question.b)+" "),(this.question.bcorrect === 1 && this.question.answerShow)?_c('span',{staticClass:"correct"},[_vm._v(" 本题答案")]):_vm._e(),(
            this.question.bcorrect === 1 && this.question.mychoiceB === '1'
          )?_c('span',{staticClass:"right"},[_vm._v(" √ 选择正确")]):(
            this.question.answerShow &&
            this.question.bcorrect === 1 &&
            this.question.mychoiceB === ''
          )?_c('span',{staticClass:"wrong"},[_vm._v(" × 未选择")]):(
            this.question.answerShow &&
            this.question.bcorrect !== 1 &&
            this.question.mychoiceB === '1'
          )?_c('span',{staticClass:"wrong"},[_vm._v(" × 选择错误")]):_vm._e()]):_vm._e(),(this.question.c !== '-' || this.question.c === '')?_c('van-checkbox',{staticClass:"question-item",attrs:{"name":"3","shape":"square"},on:{"click":function($event){return _vm.choice('3')}}},[_vm._v(_vm._s(this.question.c)+" "),(this.question.ccorrect === 1 && this.question.answerShow)?_c('span',{staticClass:"correct"},[_vm._v(" 本题答案")]):_vm._e(),(
            this.question.ccorrect === 1 && this.question.mychoiceC === '1'
          )?_c('span',{staticClass:"right"},[_vm._v(" √ 选择正确")]):(
            this.question.answerShow &&
            this.question.ccorrect === 1 &&
            this.question.mychoiceC === ''
          )?_c('span',{staticClass:"wrong"},[_vm._v(" × 未选择")]):(
            this.question.answerShow &&
            this.question.ccorrect !== 1 &&
            this.question.mychoiceC === '1'
          )?_c('span',{staticClass:"wrong"},[_vm._v(" × 选择错误")]):_vm._e()]):_vm._e(),(this.question.d !== '-' || this.question.d === '')?_c('van-checkbox',{staticClass:"question-item",attrs:{"name":"4","shape":"square"},on:{"click":function($event){return _vm.choice('4')}}},[_vm._v(_vm._s(this.question.d)+" "),(this.question.dcorrect === 1 && this.question.answerShow)?_c('span',{staticClass:"correct"},[_vm._v(" 本题答案")]):_vm._e(),(
            this.question.dcorrect === 1 && this.question.mychoiceD === '1'
          )?_c('span',{staticClass:"right"},[_vm._v(" √ 选择正确")]):(
            this.question.answerShow &&
            this.question.dcorrect === 1 &&
            this.question.mychoiceD === ''
          )?_c('span',{staticClass:"wrong"},[_vm._v(" × 未选择")]):(
            this.question.answerShow &&
            this.question.dcorrect !== 1 &&
            this.question.mychoiceD === '1'
          )?_c('span',{staticClass:"wrong"},[_vm._v(" × 选择错误")]):_vm._e()]):_vm._e(),(this.question.e !== '-' || this.question.e === '')?_c('van-checkbox',{staticClass:"question-item",attrs:{"name":"5","shape":"square"},on:{"click":function($event){return _vm.choice('5')}}},[_vm._v(_vm._s(this.question.e)+" "),(this.question.ecorrect === 1 && this.question.answerShow)?_c('span',{staticClass:"correct"},[_vm._v(" 本题答案")]):_vm._e(),(
            this.question.ecorrect === 1 && this.question.mychoiceE === '1'
          )?_c('span',{staticClass:"right"},[_vm._v(" √ 选择正确")]):(
            this.question.answerShow &&
            this.question.ecorrect === 1 &&
            this.question.mychoiceE === ''
          )?_c('span',{staticClass:"wrong"},[_vm._v(" × 未选择")]):(
            this.question.answerShow &&
            this.question.ecorrect !== 1 &&
            this.question.mychoiceE === '1'
          )?_c('span',{staticClass:"wrong"},[_vm._v(" × 选择错误")]):_vm._e()]):_vm._e()],1),(this.question.answerShow)?_c('div',{staticClass:"answer"},[_c('div',{staticClass:"answer-title"},[_vm._v("试题解析")]),_c('div',{staticClass:"answer-content"},[_vm._v(" "+_vm._s(this.question.answer)+" "),(this.question.answerpic)?_c('div',[_c('img',{attrs:{"src":"this.question.answerpic","width":"80%"}})]):_vm._e()])]):_vm._e(),_c('br'),(!this.question.hassubmit)?_c('van-button',{attrs:{"type":"info","size":"small","block":""},on:{"click":_vm.submit}},[_vm._v("确认(确认后将无法修改)")]):_vm._e()],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }