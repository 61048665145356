var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(this.question)?_c('div',{key:this.question.title,staticClass:"question"},[_c('div',{staticClass:"question-type"},[_vm._v(" 单选题 ID:"+_vm._s(this.question.id)+" 难度："+_vm._s(this.question.ddf)+" ")]),_c('div',[_c('div',{staticClass:"question-title",domProps:{"innerHTML":_vm._s(this.question.title)}}),(this.question.titlepic)?_c('div',[_c('img',{attrs:{"src":"this.question.titlepic","width":"80%"}})]):_vm._e()]),_c('van-radio-group',{key:this.question.title,attrs:{"disabled":this.question.disabled},model:{value:(_vm.radio),callback:function ($$v) {_vm.radio=$$v},expression:"radio"}},[(this.question.a !== '-' || this.question.a === '')?_c('van-radio',{staticClass:"question-item",attrs:{"name":"1"},on:{"click":function($event){return _vm.choice('1')}}},[_vm._v(_vm._s(this.question.a)+" "),(this.question.correct === 1 && this.question.answerShow)?_c('span',{staticClass:"correct"},[_vm._v(" √ 本题答案")]):_vm._e(),(
            this.question.correct !== 1 &&
            this.question.answerShow &&
            this.question.mychoice === '1'
          )?_c('span',{staticClass:"wrong"},[_vm._v(" × 选择错误")]):_vm._e(),(this.question.correct === 1 && this.question.mychoice === '1')?_c('span',{staticClass:"correct"},[_vm._v(" √ 选择正确")]):_vm._e()]):_vm._e(),(this.question.b !== '-' || this.question.b === '')?_c('van-radio',{staticClass:"question-item",attrs:{"name":"2"},on:{"click":function($event){return _vm.choice('2')}}},[_vm._v(_vm._s(this.question.b)+" "),(this.question.correct === 2 && this.question.answerShow)?_c('span',{staticClass:"correct"},[_vm._v(" √ 本题答案")]):_vm._e(),(
            this.question.correct !== 2 &&
            this.question.answerShow &&
            this.question.mychoice === '2'
          )?_c('span',{staticClass:"wrong"},[_vm._v(" × 选择错误")]):_vm._e(),(this.question.correct === 2 && this.question.mychoice === '2')?_c('span',{staticClass:"correct"},[_vm._v(" √ 选择正确")]):_vm._e()]):_vm._e(),(this.question.c !== '-' || this.question.c === '')?_c('van-radio',{staticClass:"question-item",attrs:{"name":"3"},on:{"click":function($event){return _vm.choice('3')}}},[_vm._v(_vm._s(this.question.c)+" "),(this.question.correct === 3 && this.question.answerShow)?_c('span',{staticClass:"correct"},[_vm._v(" √ 本题答案")]):_vm._e(),(
            this.question.correct !== 3 &&
            this.question.answerShow &&
            this.question.mychoice === '3'
          )?_c('span',{staticClass:"wrong"},[_vm._v(" × 选择错误")]):_vm._e(),(this.question.correct === 3 && this.question.mychoice === '3')?_c('span',{staticClass:"correct"},[_vm._v(" √ 选择正确")]):_vm._e()]):_vm._e(),(this.question.d !== '-' || this.question.d === '')?_c('van-radio',{staticClass:"question-item",attrs:{"name":"4"},on:{"click":function($event){return _vm.choice('4')}}},[_vm._v(_vm._s(this.question.d)+" "),(this.question.correct === 4 && this.question.answerShow)?_c('span',{staticClass:"correct"},[_vm._v(" √ 本题答案")]):_vm._e(),(
            this.question.correct !== 4 &&
            this.question.answerShow &&
            this.question.mychoice === '4'
          )?_c('span',{staticClass:"wrong"},[_vm._v(" × 选择错误")]):_vm._e(),(this.question.correct === 4 && this.question.mychoice === '4')?_c('span',{staticClass:"correct"},[_vm._v(" √ 选择正确")]):_vm._e()]):_vm._e()],1),(this.question.answerShow)?_c('div',{staticClass:"answer"},[_c('div',{staticClass:"answer-title"},[_vm._v("试题解析")]),_c('div',{staticClass:"answer-content"},[_vm._v(_vm._s(this.question.answer))])]):_vm._e(),_c('br'),(!this.question.mychoice)?_c('van-button',{attrs:{"type":"info","size":"small","block":""},on:{"click":_vm.submit}},[_vm._v("确认(确认后将无法修改)")]):_vm._e()],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }