<template>
  <div>
    <div class="question" v-if="this.question">
      <div class="question-type">
        多选题 ID:{{ this.question.id }} 难度：{{ this.question.ddf }}
      </div>
      <div>
        <div v-html="this.question.title" class="question-title"></div>
        <div v-if="this.question.titlepic">
          <img src="this.question.titlepic" width="80%" />
        </div>
      </div>
      <van-checkbox-group
        v-model="result"
        ref="checkboxGroup"
        :disabled="this.question.disabled"
      >
        <van-checkbox
          name="1"
          class="question-item"
          v-if="this.question.a !== '-' || this.question.a === ''"
          shape="square"
          @click="choice('1')"
          >{{ this.question.a }}
          <span
            v-if="this.question.acorrect === 1 && this.question.answerShow"
            class="correct"
          >
            本题答案</span
          >
          <span
            v-if="
              this.question.acorrect === 1 && this.question.mychoiceA === '1'
            "
            class="right"
          >
            √ 选择正确</span
          >
          <span
            v-else-if="
              this.question.answerShow &&
              this.question.acorrect === 1 &&
              this.question.mychoiceA === ''
            "
            class="wrong"
          >
            × 未选择</span
          >
          <span
            v-else-if="
              this.question.answerShow &&
              this.question.acorrect !== 1 &&
              this.question.mychoiceA === '1'
            "
            class="wrong"
          >
            × 选择错误</span
          >
        </van-checkbox>
        <van-checkbox
          name="2"
          class="question-item"
          v-if="this.question.b !== '-' || this.question.b === ''"
          shape="square"
          @click="choice('2')"
          >{{ this.question.b }}
          <span
            v-if="this.question.bcorrect === 1 && this.question.answerShow"
            class="correct"
          >
            本题答案</span
          >
          <span
            v-if="
              this.question.bcorrect === 1 && this.question.mychoiceB === '1'
            "
            class="right"
          >
            √ 选择正确</span
          >
          <span
            v-else-if="
              this.question.answerShow &&
              this.question.bcorrect === 1 &&
              this.question.mychoiceB === ''
            "
            class="wrong"
          >
            × 未选择</span
          >
          <span
            v-else-if="
              this.question.answerShow &&
              this.question.bcorrect !== 1 &&
              this.question.mychoiceB === '1'
            "
            class="wrong"
          >
            × 选择错误</span
          >
        </van-checkbox>
        <van-checkbox
          name="3"
          class="question-item"
          v-if="this.question.c !== '-' || this.question.c === ''"
          shape="square"
          @click="choice('3')"
          >{{ this.question.c }}
          <span
            v-if="this.question.ccorrect === 1 && this.question.answerShow"
            class="correct"
          >
            本题答案</span
          >
          <span
            v-if="
              this.question.ccorrect === 1 && this.question.mychoiceC === '1'
            "
            class="right"
          >
            √ 选择正确</span
          >
          <span
            v-else-if="
              this.question.answerShow &&
              this.question.ccorrect === 1 &&
              this.question.mychoiceC === ''
            "
            class="wrong"
          >
            × 未选择</span
          >
          <span
            v-else-if="
              this.question.answerShow &&
              this.question.ccorrect !== 1 &&
              this.question.mychoiceC === '1'
            "
            class="wrong"
          >
            × 选择错误</span
          >
        </van-checkbox>
        <van-checkbox
          name="4"
          class="question-item"
          v-if="this.question.d !== '-' || this.question.d === ''"
          shape="square"
          @click="choice('4')"
          >{{ this.question.d }}
          <span
            v-if="this.question.dcorrect === 1 && this.question.answerShow"
            class="correct"
          >
            本题答案</span
          >
          <span
            v-if="
              this.question.dcorrect === 1 && this.question.mychoiceD === '1'
            "
            class="right"
          >
            √ 选择正确</span
          >
          <span
            v-else-if="
              this.question.answerShow &&
              this.question.dcorrect === 1 &&
              this.question.mychoiceD === ''
            "
            class="wrong"
          >
            × 未选择</span
          >
          <span
            v-else-if="
              this.question.answerShow &&
              this.question.dcorrect !== 1 &&
              this.question.mychoiceD === '1'
            "
            class="wrong"
          >
            × 选择错误</span
          >
        </van-checkbox>
        <van-checkbox
          name="5"
          class="question-item"
          v-if="this.question.e !== '-' || this.question.e === ''"
          shape="square"
          @click="choice('5')"
          >{{ this.question.e }}
          <span
            v-if="this.question.ecorrect === 1 && this.question.answerShow"
            class="correct"
          >
            本题答案</span
          >
          <span
            v-if="
              this.question.ecorrect === 1 && this.question.mychoiceE === '1'
            "
            class="right"
          >
            √ 选择正确</span
          >
          <span
            v-else-if="
              this.question.answerShow &&
              this.question.ecorrect === 1 &&
              this.question.mychoiceE === ''
            "
            class="wrong"
          >
            × 未选择</span
          >
          <span
            v-else-if="
              this.question.answerShow &&
              this.question.ecorrect !== 1 &&
              this.question.mychoiceE === '1'
            "
            class="wrong"
          >
            × 选择错误</span
          >
        </van-checkbox>
      </van-checkbox-group>

      <div v-if="this.question.answerShow" class="answer">
        <div class="answer-title">试题解析</div>
        <div class="answer-content">
          {{ this.question.answer }}
          <div v-if="this.question.answerpic">
            <img src="this.question.answerpic" width="80%" />
          </div>
        </div>
      </div>
      <br />
      <van-button
        type="info"
        size="small"
        block
        @click="submit"
        v-if="!this.question.hassubmit"
        >确认(确认后将无法修改)</van-button
      >
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
const api = new Vue()
export default {
  data () {
    return {
      result: [],
      mychoiceA: '',
      mychoiceB: '',
      mychoiceC: '',
      mychoiceD: '',
      mychoiceE: '',
      answerShow: false,
      correct: true
    }
  },
  props: ['question'],
  methods: {
    choice (e) {
      //   alert(e)
      if (!this.question.answerShow) {
        if (e === '1') {
          this.mychoiceA = this.mychoiceA ? '' : '1'
        }
        if (e === '2') {
          this.mychoiceB = this.mychoiceB ? '' : '1'
        }
        if (e === '3') {
          this.mychoiceC = this.mychoiceC ? '' : '1'
        }
        if (e === '4') {
          this.mychoiceD = this.mychoiceD ? '' : '1'
        }
        if (e === '5') {
          this.mychoiceE = this.mychoiceE ? '' : '1'
        }
      }
    },
    submit () {
      const localquestion = this.question
      //   const localquestion = JSON.parse(localStorage.getItem('question'))
      const acorrectChoice = localquestion.acorrect.toString()
      const bcorrectChoice = localquestion.bcorrect.toString()
      const ccorrectChoice = localquestion.ccorrect.toString()
      const dcorrectChoice = localquestion.dcorrect.toString()
      const ecorrectChoice = localquestion.ecorrect.toString()
      if (this.mychoiceA === '' && this.mychoiceB === '' && this.mychoiceC === '' && this.mychoiceD === '' && this.mychoiceE === '') {
        api.$msg('您还没有选择答案呢')
        return
      }
      if ((this.mychoiceA !== '1' && acorrectChoice === '1') || (this.mychoiceA === '1' && acorrectChoice !== '1')) {
        this.correct = false
      }
      if ((this.mychoiceB !== '1' && bcorrectChoice === '1') || (this.mychoiceB === '1' && bcorrectChoice !== '1')) {
        this.correct = false
      }
      if ((this.mychoiceC !== '1' && ccorrectChoice === '1') || (this.mychoiceC === '1' && ccorrectChoice !== '1')) {
        this.correct = false
      }
      if ((this.mychoiceD !== '1' && dcorrectChoice === '1') || (this.mychoiceD === '1' && dcorrectChoice !== '1')) {
        this.correct = false
      }
      if ((this.mychoiceE !== '1' && ecorrectChoice === '1') || (this.mychoiceE === '1' && ecorrectChoice !== '1')) {
        this.correct = false
      }
      this.disabled = true
      if (!this.correct) {
        this.answerShow = true
      } else {
        this.$store.dispatch('updateScore', {
          uid: this.uid,
          score: this.question.score,
          field: 'wisdom'
        })
      }
      // 更新数据
      this.localquestionArr = this.question
      this.localquestionArr.uid = this.uid
      this.localquestionArr.mychoiceA = this.mychoiceA
      this.localquestionArr.mychoiceB = this.mychoiceB
      this.localquestionArr.mychoiceC = this.mychoiceC
      this.localquestionArr.mychoiceD = this.mychoiceD
      this.localquestionArr.mychoiceE = this.mychoiceE
      this.localquestionArr.studytime = Date.parse(new Date()) / 1000
      this.localquestionArr.correctIf = this.correct
      this.localquestionArr.answerShow = this.answerShow
      this.localquestionArr.disabled = this.disabled
      this.localquestionArr.hassubmit = true
      var localPaperInfo = this.getPaperInfo
      //   console.log(localPaperInfo, 'localPaperInfo++++')
      this.$store.commit('getPaperQuestionInfoMutation', this.localquestionArr)
      localPaperInfo.push(this.localquestionArr)
      localStorage.setItem('uidPaperInfo', JSON.stringify(localPaperInfo))

      this.$store.commit('uidPaperInfo', localPaperInfo)
    }
  },
  computed: {
    uid () {
      return localStorage.getItem('uid')
    },
    pointid () {
      return localStorage.getItem('TestPointid')
    },
    courseid () {
      return localStorage.getItem('StudyCourseId')
    },
    getPaperInfo () {
      var uidPaperInfo = localStorage.getItem('uidPaperInfo')
      if (uidPaperInfo === '') uidPaperInfo = null
      var uidPaperInfoArr = []
      if (uidPaperInfo !== null) {
        uidPaperInfoArr = JSON.parse(uidPaperInfo)
      }
      return uidPaperInfoArr
    }
  },
  watch: {
    question () {
      this.radio = ''
      this.mychoice = ''
      this.answerShow = false
      this.disabled = false
      this.correctIf = false
      this.result = []
    }
  },
  beforeUnmount () {
    this.$store.commit('getPaperQuestionInfoMutation', [])
  }

}
</script>

<style lang="scss" scoped>
.question {
  padding: 1.389vw;
  background-color: rgb(243, 233, 217);
}
.question-type {
  color: #555;
  font-size: 4.167vw;
  padding-left: 1.389vw;
}
.question-title {
  color: #111;
  font-size: 4.167vw;
  line-height: 8.333vw;
  padding: 2.778vw;
  padding-top: 3.778vw;
}
.question-item {
  padding-left: 2.778vw;
}
::v-deep .van-checkbox__label {
  line-height: 8.333vw;
  color: rgb(90, 102, 56);
  padding-top: 2.778vw;
  font-size: 4.167vw;
}
::v-deep .van-checkbox__icon {
  padding-top: 2.778vw;
}
.answer {
  background-color: rgb(197, 230, 245);
  padding: 1.389vw;
  font-size: 4.167vw;
}
.answer-title {
  line-height: 8.333vw;
  color: rgb(66, 107, 109);
}
.answer-content {
  color: cadetblue;
  line-height: 5.556vw;
}
.correct {
  color: rgb(144, 218, 247);
  font-size: 3.056vw;
}
.wrong {
  color: red;
  font-size: 3.056vw;
}
.right {
  color: rgb(9, 71, 9);
  font-size: 3.056vw;
  font-weight: bold;
}
.wait {
  margin: 2.778vw auto;
  font-size: 3.611vw;
  color: coral;
  width: 55.556vw;
}
</style>
